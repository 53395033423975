.button:hover {
    opacity: 0.8;
}

.plus:before {
    content: '';
    width: 12px;
    height: auto;
    border: 0.5px solid theme('colors.white');
    background-color: theme('colors.white');
    position: absolute;
    top: 50%;
    right: 24px;
    transform: rotate(90deg);
}

.plus:after {
    content: '';
    width: 12px;
    height: auto;
    border: 0.5px solid theme('colors.white');
    background-color: theme('colors.white');
    position: absolute;
    top: 50%;
    right: 24px;
}

.minus {
    width: 12px;
    height: auto;
    border: 0.5px solid theme('colors.primary');
    background-color: theme('colors.primary');
    position: absolute;
    top: 50%;
    right: 24px;
}

.accordionPanel {
    animation: fadeIn 0.35s ease-in;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 767px) {
    .container {
        flex-direction: column;
        width: 311px;
    }

    .button {
        width: 279px;
        margin-left: 0;
        font-size: 12px;
        padding: 10px auto;
    }

    .text {
        font-size: 12px;
    }

    .projectName {
        height: 26px;
    }

    .projectValue {
        width: 200px;
    }
}
