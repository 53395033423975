#titleNumber {
    font-family: 'Noto Serif JP', serif;
}

#titleText {
    font-family: 'Noto Serif JP', serif;
}

#linkButton {
    background: theme('colors.secondary');
}

#arrow {
    transform: rotate(45deg);
}

/* ボタンアニメーション */

#linkButton:before {
    content: '';
    z-index: -10;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    display: block;
    position: absolute;
    top: 0%;
    left: 0%;
    border: 1px solid theme('colors.secondary');
    background: theme('colors.secondary');
}

#linkButton:hover {
    box-shadow: 1px 1px 10px gray;
}

#linkButton:hover::before {
    animation: pulse 0.5s linear 1;
}

@keyframes pulse {
    0% {
        transform: scale(1, 1);
        opacity: 0.5;
    }

    100% {
        transform: scale(1.1, 1.4);
        opacity: 0;
    }
}
