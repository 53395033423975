.topLink:hover,
.bottomLink:hover {
    box-shadow: 1px 1px 5px gray;
}

.bottomLink:hover {
    opacity: 0.8;
}

.key:before {
    content: ' ';
    left: 690px;
    top: calc(50% - 7.5px);
    width: 15px;
    height: 15px;
    border-top: 2px solid theme('colors.primary');
    border-right: 2px solid theme('colors.primary');
    transform: rotate(45deg);
    position: absolute;
}

.key:after {
    content: ' ';
    left: 680px;
    top: calc(50% - 7.5px);
    width: 15px;
    height: 15px;
    border-top: 2px solid theme('colors.primary');
    border-right: 2px solid theme('colors.primary');
    transform: rotate(45deg);
    position: absolute;
}

.title {
    font-family: 'Noto Serif JP', serif;
}

.key3 {
    left: 470px;
    top: calc(50% - 6.4px);
    width: 12.8px;
    height: 12.8px;
    border-top: 2px solid theme('colors.white');
    border-right: 2px solid theme('colors.white');
    transform: rotate(45deg);
    position: absolute;
    z-index: 1;
}

.swiperBox {
    width: 1000px;
}

.prev:before {
    cursor: pointer;
    position: absolute;
    content: ' ';
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: rgba(34, 34, 34, 0.4);
    z-index: 100;
    top: calc(50% - 22px);
    left: 65px;
}

.prev:after {
    cursor: pointer;
    position: absolute;
    content: ' ';
    width: 12px;
    height: 12px;
    border-top: 3px solid theme('colors.white');
    border-right: 3px solid theme('colors.white');
    transform: matrix(-0.71, 0.71, 0.71, 0.71, 0, 0);
    z-index: 100;
    top: calc(50% - 6px);
    left: 83px;
}

.next:before {
    cursor: pointer;
    position: absolute;
    content: ' ';
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: rgba(34, 34, 34, 0.4);
    z-index: 100;
    top: calc(50% - 22px);
    right: 65px;
}

.next:after {
    cursor: pointer;
    position: absolute;
    content: ' ';
    width: 12px;
    height: 12px;
    border-top: 3px solid theme('colors.white');
    border-right: 3px solid theme('colors.white');
    transform: matrix(0.71, -0.71, -0.71, -0.71, 0, 0);
    z-index: 100;
    top: calc(50% - 6px);
    right: 83px;
}

.prev:hover:before,
.prev:hover:after,
.next:hover:before,
.next:hover:after {
    opacity: 0.8;
}

/* レスポンシブ対応 */

@media screen and (max-width: 1150px) {
    .projectList {
        width: 700px;
    }

    .swiperBox {
        width: 670px;
    }

    .prev:before {
        top: calc(50% - 22px);
        left: -15px;
    }

    .prev:after {
        top: calc(50% - 6px);
        left: 3px;
    }

    .next:before {
        top: calc(50% - 22px);
        right: -15px;
    }

    .next:after {
        top: calc(50% - 6px);
        right: 3px;
    }
}

@media screen and (max-width: 767px) {
    .topLink {
        width: 343px;
        font-size: 16px;
        text-align: left;
    }

    .topLink p {
        width: 229px;
        margin-left: 24px;
        line-height: 22px;
    }

    .key:before {
        left: 300px;
        top: calc(50% - 4px);
        width: 9px;
        height: 9px;
        border-top: 2px solid theme('colors.primary');
        border-right: 2px solid theme('colors.primary');
    }

    .key:after {
        left: 308px;
        top: calc(50% - 4px);
        width: 9px;
        height: 9px;
        border-top: 2px solid theme('colors.primary');
        border-right: 2px solid theme('colors.primary');
    }

    .title {
        font-size: 18px;
        font-weight: 700;
        margin-top: 40px;
    }

    .bar {
        width: 40px;
        height: 2px;
        margin-top: 12px;
    }

    .subTitle {
        font-size: 16px;
        margin: 24px auto;
    }

    .projectList {
        width: 370px;
        margin-bottom: 40px;
    }

    .swiperBox {
        width: 320px;
    }

    .prev:before {
        left: 8px;
        width: 32px;
        height: 32px;
        top: calc(50% - 16px);
    }

    .prev:after {
        left: 20px;
        border-top: 2px solid theme('colors.white');
        border-right: 2px solid theme('colors.white');
        top: calc(50%-6px);
    }

    .next:before {
        right: 8px;
        width: 32px;
        height: 32px;
        top: calc(50% - 16px);
    }

    .next:after {
        right: 20px;
        border-top: 2px solid theme('colors.white');
        border-right: 2px solid theme('colors.white');
        top: calc(50% - 6px);
    }

    .bottomLink {
        width: 312px;
        height: 48px;
        line-height: 16px;
        color: theme('colors.white');
        margin-bottom: 40px;
    }

    .key3 {
        left: 273px;
        top: calc(50% - 6px);
        width: 12px;
        height: 12px;
        border-top: 2px solid theme('colors.white');
        border-right: 2px solid theme('colors.white');
    }
}
