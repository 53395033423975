.signUpButton {
  background: linear-gradient(270deg, #F95D02 0%, #E8992F 74.62%, #F98002 97.5%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.signInButton {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
