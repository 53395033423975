.slash {
    font-size: 46px;
    line-height: 66px;
    font-family: 'Noto Serif JP', serif;
    width: 13px;
}

.title {
    font-family: 'Noto Serif JP', serif;
}

.titleNumber {
    font-size: 46px;
    line-height: 66px;
    font-family: 'Noto Serif JP', serif;
}

@media screen and (max-width: 767px) {
    .titleNumber,
    .slash {
        font-size: 32px;
    }

    .title {
        line-height: 26px;
    }

    .body {
        margin: 24px 16px;
        font-size: 16px;
        line-height: 33px;
        text-align: left;
    }
}
