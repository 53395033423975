#outerCard {
    border-image: linear-gradient(
            80.31deg,
            #a68c4e 32.14%,
            #eddaa8 60.38%,
            #a68c4f 115.08%
        )
        1;
}

#line:before,
#line:after {
    width: 108px;
    content: '';
    flex-grow: 1;
    height: 1px;
    background: theme(colors.primary);
}

#line:before {
    margin: 50px 12px 37px 24px;
}

#line:after {
    margin: 50px 24px 37px 12px;
}

#title {
    font-family: 'Noto Serif JP', serif;
}

@media screen and (max-width: 767px) {
    #line:before,
    #line:after {
        width: 76px;
        content: '';
        flex-grow: 1;
        height: 1px;
        background: theme(colors.primary);
    }

    #line:before {
        margin: 35px 8px 27px 16px;
    }

    #line:after {
        margin: 35px 16px 27px 8px;
    }
}
