.key:before {
    cursor: pointer;
    position: absolute;
    content: ' ';
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: theme('colors.primary');
    z-index: 100;
    top: calc(50% - 11px);
    right: 0px;
}

.key:after {
    cursor: pointer;
    position: absolute;
    content: ' ';
    width: 8px;
    height: 8px;
    border-top: 2px solid theme('colors.white');
    border-right: 2px solid theme('colors.white');
    transform: matrix(0.71, -0.71, -0.71, -0.71, 0, 0);
    z-index: 100;
    top: calc(50% - 4px);
    right: 8px;
}