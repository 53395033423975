.slash {
    font-size: 46px;
    line-height: 66px;
    font-family: 'Noto Serif JP', serif;
    width: 13px;
}

.title {
    font-family: 'Noto Serif JP', serif;
}

.titleNumber {
    font-size: 46px;
    line-height: 66px;
    font-family: 'Noto Serif JP', serif;
}

.signupButton {
    background: theme('colors.secondary');
    height: 72px;
    width: 526px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 4px;
    color: theme('colors.white');
    font-weight: bold;
    position: relative;
    z-index: 100;
}

.signupButton:before {
    content: '';
    border: 1px solid theme('colors.secondary');
    background: theme('colors.secondary');
    border-radius: 4px;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0%;
    z-index: -10;
}

.signupButton:hover {
    box-shadow: 1px 1px 10px gray;
}

.signupButton:hover:before {
    animation: pulse 0.5s linear 1;
}

@keyframes pulse {
    0% {
        transform: scale(1, 1);
        opacity: 0.5;
    }

    100% {
        transform: scale(1.1, 1.4);
        opacity: 0;
    }
}

.key {
    left: 475px;
    top: calc(50% - 10px);
    width: 20px;
    height: 20px;
    border-top: 3px solid theme('colors.white');
    border-right: 3px solid theme('colors.white');
    transform: rotate(45deg);
    position: absolute;
    z-index: 1;
}

@media screen and (max-width: 767px) {
    .titleNumber,
    .slash {
        font-size: 32px;
    }

    .title {
        line-height: 26px;
    }

    .body {
        margin: 24px 16px;
        font-size: 16px;
        line-height: 33px;
        text-align: left;
    }
}
