#caption {
    background: theme('colors.gold');
    transform: skewX(-15deg);
}

#innerCaption {
    font-family: 'Noto Serif JP', serif;
}

@media screen and (min-width: 769px) {
    #bg {
        background-image: url('../../public/images/introduction.png');
    }
}

@media screen and (max-width: 768px) {
    #contentsSpBg {
        background-color: theme(colors.primary);
    }

    #titleSpBg {
        background-image: url('../../public/images/introduction.png');
        width: 100%;
        max-height: 200px;
    }
}
