.cardTitle {
    background-color: theme('colors.primary');
    color: theme('colors.white');
    padding: 15px 24px;
    border-radius: 4px 4px 0 0;
}

.cardMain {
    border: 1px solid #e1e1e1;
    border-radius: 0 0 4px 4px;
    background-color: theme('colors.white');
}

.item {
    padding: 2px 16px;
    border: 1px solid theme('colors.primary');
}

.titleText{
    overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
