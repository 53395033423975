.date {
    color: #A0A2A6;
}

.link:hover {
    text-decoration: underline;
}

.key {
    margin: 12px 20px 0 auto;
    top: calc(50% - 6px);
    width: 12px;
    height: 12px;
    border-top: 2px solid #A0A2A6;
    border-right: 2px solid #A0A2A6;
    transform: rotate(45deg);
    z-index: 1;
}

.key:hover {
    border-color: theme('colors.primary');
    cursor: pointer;
}