@font-face{
    font-family: 'Noto Serif JP';
    src: url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@700&display=swap')
}

#dliPlus {
  width: 100%;
  height: 1.67px;
  background: currentColor;
}

#dliPlus::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  border-radius: inherit;
  transform: rotate(90deg);
}

#dliMinus {
  width: 1em;
  height: 0.1em;
  background: currentColor;
  border-radius: 0.1em;
}

@media screen and (max-width: 767px) {
  #dliPlus {
    width: 100%;
    height: 1.33px;
    background: currentColor;
  }
  
  #dliPlus::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: inherit;
    transform: rotate(90deg);
  }
  
  #dliMinus {
    width:100%;
    height: 1.33px;
    background: currentColor;
    border-radius: 0.1em;
  }
}
